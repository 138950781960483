// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: {
    user: 'gft',
    pass: 'Gftcxlab1234',
    auth: {
      url: '/soo/rest/services/auth/jwt/authenticate',
      user: 'root',
      pass: 'afire-ZQfX6X'
    },
    url: '/soo/rest/services/projects/FischerTechnik'
  },
  mqtt: {
      server: 'sphinx.innovationatgft.com',
      options: {
        hostname: 'sphinx.innovationatgft.com',
        path: '/activemq-stomp',
        username: 'gft',
        password: 'Gftcxlab1234',
        protocol: 'wss'
      }
  }// ,
    // backend: {
    //   mocked: false,
    //   user: 'ddgo@team06',
    //   pass: 'accesoSphinx',
    // },
    // api_config: {
    //   node_history: {
    //     url:
    //       '/api/projects/{{project}}/values/{{node}}/history?dataTypeHint=DOUBLE&isPeriod=false&nullifyMembers=true',
    //     project: '{{project}}',
    //     value: '{{node}}',
    //   },
    // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
