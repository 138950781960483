import { MockDataService } from './services/mock-data.service';
import { ThemeModule } from './../styles/theme.module';
import { AuthInterceptor } from './auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { SharedComponentsModule } from './components/shared-components.module';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { LoginComponent } from './page/login/login.component';
import { FormsModule } from '@angular/forms';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment } from '../environments/environment';
import { AuthGuard } from './auth.guard';

const MQTT_SERVICE_OPTIONS = environment.mqtt.options;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ThemeModule.forRoot(),
    GaugesModule,
    FormsModule,
    MqttModule.forRoot({
      hostname: 'sphinx.innovationatgft.com',
      path: '/activemq-stomp',
      username: 'gft',
      password: 'Gftcxlab1234',
      protocol: 'wss'
    }),
  ],
  providers: [
    MockDataService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '/sphinx' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
