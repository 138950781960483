import { NbJSThemeOptions, CORPORATE_THEME as baseTheme } from '@nebular/theme';

const baseThemeVariables = baseTheme.variables;

export const CORPORATE_THEME = {
  name: 'corporate',
  base: 'corporate',
  variables: {

    countryMap: {
      countryBorderColor: baseThemeVariables.border4,
      countryFillColor: baseThemeVariables.bg3,
      countryBorderWidth: '1',
      hoveredCountryBorderColor: baseThemeVariables.primary,
      hoveredCountryFillColor: baseThemeVariables.primaryLight,
      hoveredCountryBorderWidth: '1',

      chartAxisLineColor: baseThemeVariables.border4,
      chartAxisTextColor: baseThemeVariables.fg,
      chartAxisFontSize: '16',
      chartGradientTo: baseThemeVariables.primary,
      chartGradientFrom: baseThemeVariables.primaryLight,
      chartAxisSplitLine: baseThemeVariables.separator,
      chartShadowLineColor: baseThemeVariables.primaryLight,

      chartLineBottomShadowColor: baseThemeVariables.primary,

      chartInnerLineColor: baseThemeVariables.bg2,
    },
    lineTheme: {
      gradFrom: baseThemeVariables.primary,
      gradTo: baseThemeVariables.primary,

      tooltipTextColor: baseThemeVariables.fgText,
      tooltipFontWeight: 'normal',
      tooltipFontSize: '16',
      tooltipBg: baseThemeVariables.bg,
      tooltipBorderColor: baseThemeVariables.border2,
      tooltipBorderWidth: '1',
      tooltipExtraCss: 'border-radius: 10px; padding: 4px 16px;',
      lineColors: ['#26c1c9', '#81c926', '#ab7df6', '#faca00']
    },
    pieTheme: {
      legendTextColor: '#000',
      labelTextColor:  '#000',
    },
    scatterTheme: {
      legendTextColor: '#000',
      labelTextColor:  '#000',
      axisColor: '#4a4b4a',
      symbolColor: ['black', 'red'],
      splitLine: '#4a4b4a'
    },
    barTheme: {
      legendTextColor: '#000',
      labelTextColor: '#000',
      axisColor: '#4a4b4a',
      barColors: ['#26c1c9', '#faca00', '#ab7df6'],
      splitLine: '#4a4b4a'
    },
    doughnutTheme: {
      legendTextColor: '#000',
      labelTextColor: '#000',
      doughnutColors: ['#81c926', '#26c1c9', '#ab7df6', '#faca00']
    },
    gaugeTheme: {
      arcColors: ['#81c926', '#faca00', '#f44336' ],
      labelColor: '#26c1c9',
      gaugeFillColor: {ok: '#81c926', warn: '#faca00', error: '#f44336'}
    }
  },
} as NbJSThemeOptions;
