<nb-layout>
  <nb-sidebar [fixed]="false" [state]="sidenavState" tag="left"><app-menu (sidenav)="toggleSidenav($event)"></app-menu></nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <img src="assets/images/gft_logotip.png" alt="" width="150">
  </nb-layout-footer>

</nb-layout>
