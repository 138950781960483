import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { LiveUpdateChart } from '../models/earnings';
import { HeaderInfo } from '../models/header.info';
import { NbThemeService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {

  private currentDate: Date = new Date();
  private currentValue = Math.random() * 1000;
  private ONE_DAY = 24 * 3600 * 1000;
  private doughnutTheme;
  private liveUpdateChartData = {
    bitcoin: {
      liveChart: [],
      delta: {
        up: true,
        value: 4,
      },
      dailyIncome: 45895,
    },
    tether: {
      liveChart: [],
      delta: {
        up: false,
        value: 9,
      },
      dailyIncome: 5862,
    },
    ethereum: {
      liveChart: [],
      delta: {
        up: false,
        value: 21,
      },
      dailyIncome: 584,
    },
  };

  constructor(private themeService: NbThemeService) { 
    this.themeService.getJsTheme()
      .subscribe(config => {
        this.doughnutTheme = config.variables.doughnutTheme;
      });
  }

  getDefaultLiveChartData(elementsNumber: number) {
    this.currentDate = new Date();
    this.currentValue = Math.random() * 1000;

    return Array.from(Array(elementsNumber))
      .map(item => this.generateRandomLiveChartData());
  }

  generateRandomLiveChartData() {
    this.currentDate = new Date(+this.currentDate + this.ONE_DAY);
    this.currentValue = this.currentValue + Math.random() * 20 - 11;

    if (this.currentValue < 0) {
      this.currentValue = Math.random() * 100;
    }

    return {
      value: [
        [
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          this.currentDate.getDate(),
        ].join('/'),
        Math.round(this.currentValue),
      ],
    };
  }

  getEarningLiveUpdateCardData(currency): Observable<any[]> {
    const data = this.liveUpdateChartData[currency.toLowerCase()];
    const newValue = this.generateRandomLiveChartData();

    data.liveChart.shift();
    data.liveChart.push(newValue);

    return observableOf(data.liveChart);
  }

  getEarningCardData(currency: string): Observable<LiveUpdateChart> {
    const data = this.liveUpdateChartData[currency.toLowerCase()];

    data.liveChart = this.getDefaultLiveChartData(150);

    return observableOf(data);
  }

  getHeaderInfo(): Observable<HeaderInfo[]> {
    const data: Array<HeaderInfo> = [
      {title: 'PROCESS', icon: 'arrow-up', value: 23876.12},
      {title: 'PIECES COLLECTED', icon: 'cube-green', value: 276},
      {title: 'PIECES MOVED', icon: 'cube-blue', value: 273},
      {title: 'LOSES WORKPIECES', icon: 'arrow-down', value: 12},
      {title: 'PROCESS', icon: 'arrow-up', value: 23876.12},
      {title: 'PIECES COLLECTED', icon: 'cube-green', value: 276},
      {title: 'PIECES MOVED', icon: 'cube-blue', value: 273},
      {title: 'LOSES WORKPIECES', icon: 'arrow-down', value: 12},
      {title: 'PROCESS', icon: 'arrow-up', value: 23876.12},
      {title: 'PIECES COLLECTED', icon: 'cube-green', value: 276},
      {title: 'PIECES MOVED', icon: 'cube-blue', value: 273},
      {title: 'LOSES WORKPIECES', icon: 'arrow-down', value: 12},
      {title: 'PROCESS', icon: 'arrow-up', value: 23876.12},
      {title: 'PIECES COLLECTED', icon: 'cube-green', value: 276},
      {title: 'PIECES MOVED', icon: 'cube-blue', value: 273},
      {title: 'LOSES WORKPIECES', icon: 'arrow-down', value: 12},
      {title: 'PROCESS', icon: 'arrow-up', value: 23876.12},
      {title: 'PIECES COLLECTED', icon: 'cube-green', value: 276},
      {title: 'PIECES MOVED', icon: 'cube-blue', value: 273},
      {title: 'LOSES WORKPIECES', icon: 'arrow-down', value: 12},
      {title: 'PROCESS', icon: 'arrow-up', value: 23876.12},
      {title: 'PIECES COLLECTED', icon: 'cube-green', value: 276},
      {title: 'PIECES MOVED', icon: 'cube-blue', value: 273},
      {title: 'LOSES WORKPIECES', icon: 'arrow-down', value: 12},
    ];
    return observableOf(data);
  }

  generatePieChartData() {
    const data: Array<any> = [{
      name: 'Apples',
          value: 70
      }, {
          name: 'Strawberries',
          value: 68
      }, {
          name: 'Bananas',
          value: 48
      }, {
          name: 'Oranges',
          value: 40
      }, {
          name: 'Pears',
          value: 32
      }, {
          name: 'Pineapples',
          value: 27
      }, {
          name: 'Grapes',
          value: 18
    }];
    return observableOf(data);
  }

  generateBarContent() {
    const data = {
      serieOne: [1200, 2000, 1500, 800, 700, 1100, 1300, 1200, 2000, 1500, 800, 700],
      serieTwo: [2200, 1500, 1200, 2200, 1600, 1351, 1041, 1311, 1500, 1231],
      serieThree: [2500, 2000, 1500, 2100, 1300, 1451, 1241, 1111, 1000, 1331],
    };
    return observableOf(data);
  }

  generateDonutData() {
    const data: Array<any> = [
        {value: 335, name: 'A', itemStyle: {color: this.doughnutTheme.doughnutColors[0]}},
        {value: 310, name: 'B', itemStyle: {color: this.doughnutTheme.doughnutColors[1]}},
        {value: 234, name: 'C', itemStyle: {color: this.doughnutTheme.doughnutColors[2]}},
        {value: 135, name: 'D', itemStyle: {color: this.doughnutTheme.doughnutColors[3]}},
    ];
    return observableOf(data);
  }

  generateLineData() {
    const data = {
      serieOne: [820, 932, 901, 934, 1290, 1330, 1320],
      serieTwo: [240, 125, 500, 300, 400, 700, 900]
    };
    return observableOf(data);
  }

  generateScatterData() {
    const data = {
      serieOne: [
          [10.0, 8.04],
          [8.0, 6.95],
          [13.0, 7.58],
          [9.0, 8.81],
          [11.0, 8.33],
          [14.0, 9.96],
          [6.0, 7.24],
          [4.0, 4.26],
          [12.0, 10.84],
          [7.0, 4.82],
          [5.0, 5.68]
      ],
      serieTwo: [
          [20.0, 4.04],
          [18.0, 2.95],
          [4.0, 17.58],
          [2.0, 5.81],
          [1.0, 7.33],
          [4.0, 6.96],
          [36.0, 17.24],
          [34.0, 14.26],
          [2.0, 20.84],
          [10.0, 4.82],
          [5.0, 15.68]
      ],
    };
    return observableOf(data);
  }

}
