import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Order } from '../models/order.model';
import { Alert } from '../models/alert.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SphinxService {

  private alertDataSource = new BehaviorSubject<Array<Alert>>([]);
  private ambientIndicatorsSource = new BehaviorSubject<any>({});
  private moduleStateSource = new BehaviorSubject<any>({});
  private warehouseSource = new BehaviorSubject<any>({});
  private rawMaterialSource = new BehaviorSubject<any>({});
  alerts = this.alertDataSource.asObservable();
  ambientIndicators = this.ambientIndicatorsSource.asObservable();
  moduleState = this.moduleStateSource.asObservable();
  warehouse = this.warehouseSource.asObservable();
  rawMaterial = this.rawMaterialSource.asObservable();
  authToken: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient) {
    console.log('ENVIRONMENT', environment.backend.url);
  }

  login() {
    const credentials = {
      username: environment.backend.auth.user,
      password: environment.backend.auth.pass
    };
    return this.http.post(`${environment.backend.auth.url}`, JSON.stringify(credentials));
  }

  // async getLocations(): Promise<Location[]> {
  //   return Promise.resolve([
  //     { index: 0, id: 1, name: 'Valencia', latlng: [39.466667, -0.375], color: 'green' },
  //     { index: 1, id: 2, name: 'Madrid', latlng: [40.416775, -3.70379], color: 'red' },
  //     { index: 2, id: 3, name: 'Barcelona', latlng: [41.390205, 2.154007], color: 'blue' },
  //   ]);
  // }

  // getValues(locationID: number, nodeValue: string, history: number = -1): Observable<any> {
  //   let url = '/api/projects/team06/';

  //   if (history >= 0) {
  //     url += 'values' +
  //           '/t06engines.eng' + locationID.toString() + '.' + nodeValue +
  //           '/history?dataTypeHint=DOUBLE&isPeriod=false&nullifyMembers=true' +
  //           (history > 0 ? '&rowLimit=' + history.toString() : '');
  //   } else {
  //     url += 'nodes' +
  //           '/t06engines.eng' + locationID.toString() + '.' + nodeValue +
  //           '/value';
  //   }

  //   return this.http.get(url);
  // }

  getCords(): Observable<any> {
    return this.http.get('assets/leaflet-map/countries.json');
  }

  getHistorySensor(type, extraParams?, limit?) {
    // Type could be:
    // h, rh --> (humidity / relative humidity) [%]
    // t, rt --> (temperature / relative temperature) [°C]
    // p --> pressure [hPa]
    // iaq --> index air quality  (0-500 -> (0...50:Good, 51...100:Moderate, 101...150:Unhealthy for Sensitive Groups, 151...200:Unhealthy, 201...300:Very Unhealthy, 301...500:Hazardous))
    // aq --> air quality score (0-3 -> (0:IAQ invalid, 1:calibration necessary, 2:calibration done, 3:IAQ is calibrated))
    // gr --> gas resistance [Ohm]
    const extraParam = extraParams ? extraParams : '';
    return this.http.get(`${environment.backend.url}/values/FischerTechnik.mqtt.sensors.env.${type}/history?isPeriod=false&rowLimit=${limit ? limit : 50000}&nullifyMembers=true${extraParam}`);
  }

  getStockHistory(limit?) {
    return this.http.get(`${environment.backend.url}/values/FischerTechnik.mqtt.stockItems/history?isPeriod=false&rowLimit=${limit ? limit : 5000}&nullifyMembers=true`);
  }

  getNFCHistory(datapoint, period?, limit?) {
    let start = null;
    let end = null;
    if (period) {
      start = period === 'today' ? moment().startOf('day').format('x') : moment().subtract(1, period).format('x');
      end = moment().format('x');
    }
    const interval = period ? `&timeOfValueStart=${start}&timeOfValueEnd=${end}` : '';
    return this.http.get(`${environment.backend.url}/values/FischerTechnik.mqtt.nfc.${datapoint}/history?isPeriod=false&rowLimit=${limit ? limit : 5000}&nullifyMembers=true${interval}`);
  }

  getStateOrder(): Observable<Order> {
    return this.http.get(``);
  }

  updateAlerts(data: Alert[]){
    this.alertDataSource.next(data.reverse());
  }

  updateAmbientIndicators(data){
    this.ambientIndicatorsSource.next(data);
  }

  updateModuleState(data) {
    this.moduleStateSource.next(data);
  }

  updateWarehouse(data) {
    this.warehouseSource.next(data);
  }

  updateRawMaterial(data) {
    this.rawMaterialSource.next(data);
  }

  getFromNowToOneYearBefore() {
    const dates = [];
    for (let i = 0; i < 12; i++ ) {
      dates.push({
        month: moment().subtract(i, 'month').format('MMM'),
        startDate: +moment().subtract(i, 'month').startOf('month').format('x'),
        endDate: +moment().subtract(i, 'month').endOf('month').format('x'),
        pieces: []
      });
    }
    return dates.reverse();
  }

  getFromNowToOneMonthBefore() {
    const dates = [];
    for (let i = 0; i < 31; i++ ) {
      dates.push({
        day: +moment().subtract(i, 'day').format('DD'),
        startDate:  +moment().subtract(i, 'day').startOf('day').format('x'),
        endDate: +moment().subtract(i, 'day').endOf('day').format('x'),
        pieces: []
      });
    }
    return dates.reverse();
  }

  getFromNowToOneDayBefore() {
    const dates = [];
    for (let i = 0; i < 24; i++ ) {
      dates.push({
        hour: moment().startOf('day').add(i, 'hour').format('HH:mm'),
        startDate: +moment().startOf('day').add(i, 'hour').format('x'),
        endDate: +moment().startOf('day').add(i, 'hour').endOf('hour').format('x'),
        pieces: []
      });
    }
    return dates;
  }

}
