import { ModuleWithProviders, NgModule } from '@angular/core';
import { CORPORATE_THEME } from './theme.corporate';
import { SPHINX_THEME } from './theme.sphinx';
import {
  NbThemeModule,
  NbLayoutModule,
  NbIconModule,
  NbCardModule,
  NbToggleModule,
  NbMenuModule,
  NbSidebarModule,
  NbSidebarService,
  NbSelectModule,
  NbInputModule,
  NbButtonModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    NbLayoutModule,
    NbEvaIconsModule,
    NgbModule,
    NbIconModule,
    NbCardModule,
    NbToggleModule,
    NbSidebarModule,
    NbMenuModule.forRoot(),
    NbSelectModule,
    NbInputModule,
    NbButtonModule
  ],
  exports: [
    NbLayoutModule,
    NbEvaIconsModule,
    NgbModule,
    NbIconModule,
    NbCardModule,
    NbToggleModule,
    NbSidebarModule,
    NbMenuModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule
  ],
  providers: [
    NbSidebarService
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'sphinx',
          },
          [ CORPORATE_THEME, SPHINX_THEME ],
        ).providers,
      ],
    };
  }
}
