import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NbMenuItem, NbThemeService } from '@nebular/theme';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Output() sidenav = new EventEmitter();
  compacted = true;
  darkMode = true;

  items: NbMenuItem[] = [
    {
      title: 'Videowall',
      icon: 'home-outline',
      link: '/videowall',
    },
    {
      title: 'TouchTable',
      icon: 'layers-outline',
      link: '/touchtable',
    },
    {
      title: 'Logout',
      icon: 'unlock-outline',
    },
  ];

  constructor(private themeService: NbThemeService) { }

  ngOnInit(): void {
  }

  toggleSidenav() {
    this.compacted = !this.compacted;
    this.sidenav.emit(this.compacted);
  }

  changeTheme() {
    this.darkMode = !this.darkMode;
    this.themeService.changeTheme(this.darkMode  ? 'sphinx' : 'corporate');
  }

}
