import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor() { }

  public error(message: string, source: string = ''): void {
    console.log('Alert Message Error:');
    console.log(message);
  }

  public info(message: string): void {
    console.log('Alert Message Info:');
    console.log(message);
  }
}
