import { ThemeModule } from './../../styles/theme.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { ScatterChartComponent } from './scatter-chart/scatter-chart.component';
import { HeaderInfoComponent } from './header-info/header-info.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { GlobalPositionComponent } from './global-position/global-position.component';
import { FactoryInfoComponent } from './global-position/factory-info/factory-info.component';
import { FactoryMapComponent } from './global-position/factory-map/factory-map.component';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { AlertsComponent } from './alerts/alerts.component';
import { AmbientIndicatorsComponent } from './ambient-indicators/ambient-indicators.component';
import { PiecesInfoComponent } from './pieces-info/pieces-info.component';
import { AmbientChartComponent } from './ambient-chart/ambient-chart.component';
import { NbSelectModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    MenuComponent,
    BarChartComponent,
    PieChartComponent,
    DonutChartComponent,
    LineChartComponent,
    ScatterChartComponent,
    HeaderInfoComponent,
    ProgressBarComponent,
    GlobalPositionComponent,
    FactoryInfoComponent,
    FactoryMapComponent,
    AlertsComponent,
    AmbientIndicatorsComponent,
    PiecesInfoComponent,
    AmbientChartComponent
  ],
  exports: [
    MenuComponent,
    BarChartComponent,
    PieChartComponent,
    DonutChartComponent,
    LineChartComponent,
    ScatterChartComponent,
    HeaderInfoComponent,
    ProgressBarComponent,
    GlobalPositionComponent,
    FactoryInfoComponent,
    FactoryMapComponent,
    AlertsComponent,
    AmbientIndicatorsComponent,
    PiecesInfoComponent,
    AmbientChartComponent
  ],
  imports: [
    CommonModule,
    NgxEchartsModule,
    ThemeModule,
    GaugesModule,
    FormsModule,
  ]
})
export class SharedComponentsModule { }
