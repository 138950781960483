import { SphinxService } from './services/sphinx.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private sphinxService: SphinxService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.sphinxService.login().pipe(
        map((res: any) => {
          console.log('Login to sphinx SUCCESS');
          if (res.token) {
            this.sphinxService.authToken = res.token;
            return true;
          }
          console.log('Login to sphinx ERROR');
          return false;
      }));
  }


}
