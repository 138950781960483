import { Component } from '@angular/core';
import { NbThemeService, NbSidebarService } from '@nebular/theme';
import { Router, NavigationEnd } from '@angular/router';
import {filter, map} from 'rxjs/operators';
import { SphinxService } from './services/sphinx.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sphinx';
  sidenavState = 'compacted';
  darkMode = true;

  constructor(
    private themeService: NbThemeService,
    private sidebarService: NbSidebarService,
    private router: Router,
    private sphinxService: SphinxService
  ) {

    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => { // e is now NavigationEnd
        console.log('Navigation END', e);
        if (e.url === '/login') {
          this.sidenavState = 'collapsed';
        } else {
          this.sidenavState = 'collapsed' ? 'compacted' : this.sidenavState; // 1 - from login to any page, 2 - from page to page
        }
      })
    ).subscribe();
  }

  toggleSidenav(e) {
    this.sidenavState = e === false ? 'expanded' : 'compacted';
  }

}
